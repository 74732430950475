*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url("Montserrat-Light.a03ff5f8.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("Montserrat-Regular.a32550f7.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("Montserrat-Medium.dc3ce8d6.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("Montserrat-SemiBold.9920aea8.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("Montserrat-Bold.96db3e27.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url("Montserrat-ExtraBold.b4e4f750.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url("Montserrat-Black.6699450c.ttf") format("truetype");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("Inter-Regular.74535f8a.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  src: url("Roboto-Regular.ca197847.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  src: url("Roboto-Medium.91322885.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  color: #f3f3f3;
  background-color: #100f0f;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

body.modal-open-contact, body.modal-open-order, body.modal-menu-open {
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  cursor: pointer;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 414.5px) {
  .container {
    max-width: 415px;
  }
}

@media (min-width: 415px) {
  .container {
    width: 415px;
  }
}

@media (min-width: 1020px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 1140px;
  }
}

h1, h2, h3, h4, h5, p {
  margin-top: 0;
  margin-bottom: 0;
}

.section {
  padding-top: 124px;
  padding-bottom: 124px;
}

@media screen and (min-width: 1020px) {
  .section {
    padding-top: 132px;
    padding-bottom: 132px;
  }
}

.container__gradient {
  background: linear-gradient(248deg, #6f6666 2.17%, #100f0f 47.72%);
}

@media (min-width: 1020px) {
  .container__gradient {
    background: linear-gradient(215.51deg, #6f6666e6 1.96%, #100f0fe6 41.08% 79.08%);
  }
}

@media (min-width: 1280px) {
  .container__gradient {
    background: linear-gradient(211.52deg, #6f6666e6 4.71%, #100f0fe6 42.77% 44.21%);
  }
}

@media (min-width: 1020px) {
  .nav {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.nav__cont {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 1019.5px) {
  .nav.nav-menu__cont .nav__cont {
    margin-bottom: 28px;
  }
}

@media (min-width: 1020px) {
  .nav__cont {
    margin-right: 140px;
  }
}

.nav__icons {
  width: 32px;
  height: 32px;
  fill: #f3f3f3;
}

.logo {
  text-transform: uppercase;
  color: #f3f3f3;
  align-items: center;
  padding: 12px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
}

.logo__icon {
  margin-right: 16px;
  transition: fill .35s cubic-bezier(.4, 0, .2, 1);
  animation: 1.5s swing;
}

.logo:hover .logo__icon, .logo:focus .logo__icon {
  fill: #bc1a1a80;
  animation: 1s shake;
}

.nav__btn {
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  transition: transform .35s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

@media (min-width: 1020px) {
  .nav__btn {
    display: none;
  }
}

.nav__btn:hover, .nav__btn:focus {
  transform: scale(1.1);
}

.nav__btn .nav__btn--cross, .nav__btn.is-open-menu .nav__btn--menu {
  display: none;
}

.nav__btn.is-open-menu .nav__btn--cross {
  display: block;
}

.nav-menu__cont {
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: #100f0f;
  padding: 8px 20px;
  animation: .45s lightSpeedInRight;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

@media (max-width: 1019.5px) {
  .nav-list {
    display: none;
  }

  .nav.nav-menu__cont .nav-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1020px) {
  .nav-list {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (max-width: 1019.5px) {
  .nav-list__item {
    margin-bottom: 28px;
  }

  .nav-list__item:last-child {
    margin-bottom: 40px;
  }
}

@media (min-width: 1020px) {
  .nav-list__item:not(:last-child) {
    margin-right: 52px;
  }
}

.nav-list__link {
  color: #f3f3f3;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  transition: color .35s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

@media (min-width: 1020px) {
  .nav-list__link {
    padding: 30px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }
}

.nav-list__link:hover, .nav-list__link:focus {
  color: #bc1a1a80;
}

.nav__cont-btn {
  min-width: 160px;
  height: 40px;
  color: #f3f3f3;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.25;
  transition: border-color .35s cubic-bezier(.4, 0, .2, 1), color .35s cubic-bezier(.4, 0, .2, 1), box-shadow .35s cubic-bezier(.4, 0, .2, 1);
}

@media (max-width: 1019.5px) {
  .nav__cont-btn {
    display: none;
  }

  .nav.nav-menu__cont .nav__cont-btn {
    margin-bottom: 60px;
    display: block;
  }
}

@media (min-width: 1020px) {
  .nav__cont-btn {
    margin-right: 0;
  }
}

.nav__cont-btn:hover, .nav__cont-btn:focus {
  color: #bc1a1a80;
  border-color: #bc1a1a80;
  box-shadow: 0 4px 4px #ffffff0d;
}

.hero__wrapper {
  flex-direction: column;
  gap: 24px;
  padding: 40px 0;
  display: flex;
  position: relative;
}

@media screen and (min-width: 380px) and (max-width: 1019.5px) {
  .hero__wrapper {
    background: url("hero-frame.fcfbcc2c.webp") -15% 38% no-repeat;
  }
}

@media screen and (min-width: 380px) and (max-width: 1019.5px) and (min-device-pixel-ratio: 2), screen and (min-width: 380px) and (max-width: 1019.5px) and (min-resolution: 192dpi), screen and (min-width: 380px) and (max-width: 1019.5px) and (min-resolution: 2dppx) {
  .hero__wrapper {
    background: url("hero-frame@2x.22f340d3.webp") -15% 38% / 325px 274px no-repeat;
  }
}

@media screen and (min-width: 1020px) {
  .hero__wrapper {
    gap: 74px;
    padding: 72px 0 31px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding: 100px 0;
  }
}

.hero__content {
  flex-direction: column;
  gap: 228px;
  display: flex;
}

@media screen and (min-width: 1020px) {
  .hero__content {
    max-width: 48.0645%;
    gap: 48px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__content {
    max-width: 40.6364%;
  }
}

.hero__title-wrapper {
  z-index: 2;
}

.hero__title {
  max-width: 195px;
  text-transform: uppercase;
  align-items: center;
  margin-bottom: 16px;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.23333;
  display: flex;
}

.hero__title:before {
  content: "";
  width: 1px;
  height: 66px;
  background-color: #f3f3f3;
  margin-right: 8px;
  display: block;
}

@media screen and (min-width: 1020px) {
  .hero__title:before {
    height: 153px;
    margin-right: 12px;
  }

  .hero__title {
    max-width: 100%;
    margin-bottom: 24px;
    font-size: 64px;
    line-height: 1.21875;
  }
}

.hero__subtitle {
  letter-spacing: -.01em;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.hero__subtitle--accent {
  color: #bc1a1a;
  font-weight: 600;
}

@media screen and (min-width: 1020px) {
  .hero__subtitle--accent {
    color: #bc1a1ab5;
  }

  .hero__subtitle {
    font-size: 36px;
    font-weight: 300;
    line-height: 1.22222;
  }

  .hero__subtitle--accent {
    font-weight: 900;
  }
}

.hero__subtitle--accent {
  animation: .8s .3s backInDown;
  display: inline-block;
}

.hero__sauce-image {
  animation: 1s fadeInBottomLeft;
}

.hero__main-btn {
  min-width: 100%;
  height: 66px;
  color: #f3f3f3;
  background: #bc1a1a33;
  border: none;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 0;
  font-size: 20px;
  line-height: 1.2;
  transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.hero__main-btn:hover, .hero__main-btn:focus {
  background-color: #bc1a1a80;
}

@media screen and (min-width: 1020px) {
  .hero__main-btn {
    min-width: 302px;
    height: 77px;
    margin-bottom: 48px;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.23333;
  }
}

.hero__product-content {
  height: 78px;
  display: flex;
}

.hero__product-info {
  align-items: center;
  display: flex;
}

.hero__product-info:first-child:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #f3f3f3;
  margin: 0 22px;
}

@media screen and (min-width: 1020px) {
  .hero__product-info:first-child:after {
    margin: 0 19px 0 10px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__product-info:first-child:after {
    margin: 0 12px;
  }
}

.hero__product-quantity {
  margin-right: 12px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.21875;
}

@media screen and (min-width: 1020px) and (max-width: 1280px) {
  .hero__product-quantity {
    margin-right: 10px;
  }
}

.hero__socials {
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

@media screen and (min-width: 1280px) {
  .hero__socials {
    flex-direction: column;
    gap: 32px;
  }
}

.hero__item-link {
  display: block;
}

.hero__socials-icon {
  fill: #f3f3f3;
  transition: fill .35s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.hero__item-link:hover .hero__socials-icon, .hero__item-link:focus .hero__socials-icon {
  fill: #bc1a1a80;
}

@media screen and (min-width: 1020px) {
  .hero__socials-icon {
    width: 24px;
    height: 24px;
  }
}

.hero__img-box {
  width: auto;
  height: 350px;
  position: absolute;
  top: 24px;
  right: 0;
}

@media screen and (min-width: 1020px) {
  .hero__img-box {
    height: 579px;
    top: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .hero__img-box {
    height: 577px;
    top: 72px;
    right: 71px;
  }
}

.hero__sauce-image {
  height: 100%;
}

@media screen and (max-width: 380px) {
  .hero__sauce-image {
    opacity: .2;
  }
}

.products {
  position: relative;
}

.products-container {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.products__title {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.products__item {
  align-items: center;
  display: flex;
}

.products__item:nth-child(2n) {
  flex-direction: row-reverse;
}

@media screen and (min-width: 1280px) {
  .products__item {
    margin: 0 90px;
  }
}

@media screen and (max-width: 1019.5px) {
  .products__content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1279.5px) {
  .products__content {
    width: 406px;
    margin-left: 64px;
    margin-right: auto;
  }
}

@media screen and (min-width: 1280px) {
  .products__content {
    width: 406px;
    margin-left: 90px;
    margin-right: auto;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1279.5px) {
  .products__content--right {
    width: 443px;
    margin-left: auto;
    margin-right: 64px;
  }
}

@media screen and (min-width: 1280px) {
  .products__content--right {
    width: 443px;
    margin-left: auto;
    margin-right: 90px;
  }
}

@media screen and (max-width: 1019.5px) {
  .products__caption {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }
}

@media screen and (min-width: 1020px) {
  .products__caption {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.21875;
  }
}

@media screen and (max-width: 1019.5px) {
  .products__text {
    display: none;
  }
}

@media screen and (min-width: 1020px) {
  .products__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
  }
}

.productSlides {
  animation: .5s slideInUp;
  display: none;
}

.products__image {
  border-radius: 50%;
  box-shadow: 0 0 50px #7c7070;
}

.products__next, .products__prev {
  cursor: pointer;
  width: auto;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 3px 3px 0;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  transition: all .6s;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 50%);
}

.products__prev {
  border-radius: 3px 0 0 3px;
  top: 100%;
  transform: translate(-50%, -50%);
}

.products__icon {
  fill: #f3f3f3;
}

@media screen and (max-width: 1019.5px) {
  .products__icon {
    width: 16px;
    height: 16px;
  }
}

@media screen and (min-width: 1020px) {
  .products__icon {
    width: 32px;
    height: 32px;
  }
}

.products__icon--prev {
  transform: rotate(180deg);
}

.products__btn {
  cursor: pointer;
  color: #f3f3f3;
  background-color: #bc1a1a33;
  border: none;
  border-radius: 8px;
  transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (max-width: 1019.5px) {
  .products__btn {
    margin-top: 16px;
    padding: 4.5px 18.5px;
  }
}

@media screen and (min-width: 1020px) {
  .products__btn {
    margin-top: 20px;
    padding: 9.5px 57px;
  }
}

.products__btn:hover, .products__btn:focus {
  background-color: #bc1a1a80;
}

.recipes {
  padding-top: 20px;
  padding-bottom: 120px;
}

@media screen and (min-width: 1020px) {
  .recipes {
    padding-top: 94px;
    padding-bottom: 194px;
  }
}

.recipes__title {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

@media screen and (min-width: 1020px) {
  .recipes__list {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .recipes__list {
    justify-content: space-around;
    align-items: center;
    display: flex;
  }
}

.recipes__item {
  text-align: center;
  width: 245px;
}

.recipes__item:not(:last-child) {
  margin-bottom: 40px;
}

.recipes__item:nth-child(2n+2) {
  margin-right: auto;
}

.recipes__item:nth-child(2n+1) {
  margin-left: auto;
}

@media screen and (min-width: 1020px) {
  .recipes__item {
    width: 450px;
    height: 307px;
  }

  .recipes__item:not(:last-child) {
    margin-bottom: 0;
  }

  .recipes__item:nth-child(2n+2) {
    margin-right: 0;
  }

  .recipes__item:nth-child(2n+1) {
    margin-left: 0;
  }
}

.recipes__video-box {
  width: 245px;
  height: 236px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1020px) {
  .recipes__video-box {
    width: 450px;
    height: 307px;
  }
}

.recipes__video {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
}

.recipes__desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

@media screen and (min-width: 1020px) {
  .recipes__desc {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.21875;
  }
}

.gallery {
  padding-bottom: 157px;
}

@media (min-width: 1020px) {
  .gallery {
    padding-bottom: 131px;
  }
}

.gallery__title {
  height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.gallery__images {
  position: relative;
}

.gallery__list {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

@media (max-width: 414.5px) {
  .gallery__list {
    height: 67vw;
    width: 100%;
  }
}

@media (min-width: 415px) and (max-width: 1019.5px) {
  .gallery__list {
    height: 226px;
    width: 336px;
  }
}

@media (min-width: 1020px) and (max-width: 1279.5px) {
  .gallery__list {
    width: 770px;
    height: 504px;
  }
}

@media (min-width: 1280px) {
  .gallery__list {
    width: 1038px;
    height: 504px;
  }
}

.gallery__item {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .75s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery__item.visible {
  opacity: 1;
  z-index: 1;
}

.gallery__img {
  max-width: none;
  height: 100%;
}

.gallery__btn {
  text-align: center;
  z-index: 2;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(50% - 16px);
  overflow: hidden;
}

@media (max-width: 1019.5px) {
  .gallery__btn {
    width: 12px;
    height: 14px;
  }

  .gallery__btn__left {
    left: 2px;
  }

  .gallery__btn__right {
    right: 2px;
  }
}

@media (min-width: 1020px) and (max-width: 1279.5px) {
  .gallery__btn {
    width: 24px;
    height: 24px;
  }

  .gallery__btn__left {
    left: 29px;
  }

  .gallery__btn__right {
    right: 29px;
  }
}

@media (min-width: 1280px) {
  .gallery__btn {
    width: 24px;
    height: 24px;
  }

  .gallery__btn__left {
    left: -15px;
  }

  .gallery__btn__right {
    right: -15px;
  }
}

.gallery__btn__svg {
  fill: #f3f3f3;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.gallery__btn__left .gallery__btn__svg {
  transform: rotate(-90deg);
}

.gallery__btn__right .gallery__btn__svg {
  transform: rotate(90deg);
}

.gallery__markers {
  text-align: center;
}

.gallery__menu {
  height: 8px;
  width: 8px;
  background-color: #6f6666;
  border: 1px solid #0000;
  border-radius: 50%;
  padding: 0;
}

.gallery__menu:hover, .gallery__menu:focus, .gallery__menu.visible {
  cursor: pointer;
  border-color: #f3f3f3;
}

@media (min-width: 1020px) {
  .footer__section {
    border-top: 1px solid #f3f3f399;
  }
}

.footer {
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1020px) {
  .footer {
    display: inline;
  }
}

.footer-logo {
  align-items: center;
  display: flex;
}

.footer-logo__svg {
  fill: #f3f3f3;
  margin-right: 12px;
}

@media (min-width: 1020px) {
  .footer-logo__svg {
    margin-right: 16px;
  }
}

.footer-logo__name {
  color: #f3f3f3;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;
}

@media (min-width: 1020px) {
  .footer-logo__name {
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }
}

.footer-nav {
  gap: 20px;
  margin-top: 49px;
  display: flex;
}

@media (min-width: 1020px) {
  .footer-nav {
    flex-direction: column;
  }
}

.footer-nav__item {
  margin-bottom: 20px;
}

.footer-nav__link {
  color: #f3f3f3;
  transition: color .35s cubic-bezier(.4, 0, .2, 1);
}

.footer-nav__link:hover, .footer-nav__link:focus {
  color: #bc1a1a80;
  transition: color .35s cubic-bezier(.4, 0, .2, 1);
}

@media (min-width: 1020px) {
  .nav-contact-flex {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.contact {
  margin-top: 34px;
}

.contact__item {
  margin-bottom: 16px;
}

.contact__link {
  color: #f3f3f3;
  align-items: center;
  margin-bottom: 16px;
  transition: color .35s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.contact__link:hover, .contact__link:focus {
  color: #bc1a1a80;
}

@media (min-width: 1020px) {
  .contact__link {
    margin-bottom: 45px;
  }
}

.contact__icon {
  transition: fill .35s cubic-bezier(.4, 0, .2, 1);
}

.contact__link:hover .contact__icon, .contact__link:focus .contact__icon {
  fill: #bc1a1a80;
}

.contact__icon-location {
  fill: #f3f3f3;
  margin-right: 11.75px;
}

.contact__icon-email {
  fill: #f3f3f3;
  margin-right: 9.3px;
}

.social {
  cursor: pointer;
  margin-top: 20px;
}

@media (min-width: 1020px) {
  .social-hidden-div {
    display: none;
  }
}

@media (max-width: 1019.5px) {
  .social-hidden {
    display: none;
  }
}

.social__item {
  margin-bottom: 20px;
}

.social__icon {
  width: 16px;
  height: 16px;
  fill: #f3f3f3;
  transition: fill .35s cubic-bezier(.4, 0, .2, 1);
}

.social__link:hover .social__icon, .social__link:focus .social__icon {
  fill: #bc1a1a80;
}

@media (min-width: 1280px) {
  .social__icon {
    width: 24px;
    height: 24px;
  }
}

.conditions {
  color: #979797;
  justify-content: center;
  margin-top: 4px;
  font-family: Roboto;
  font-size: 8px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
}

.conditions .contact__item {
  margin-bottom: 16px;
}

@media (min-width: 1020px) {
  .conditions {
    margin-top: 24px;
    font-size: 14px;
    line-height: 17px;
  }
}

.conditions_link {
  color: #f3f3f3;
  text-decoration: underline;
}

.modal-cont__backdrop {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background-color: #fff3;
  padding: 40px 0;
  transition: opacity .35s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  overflow-y: scroll;
}

.modal-cont__backdrop.is-hidden-modal-cont {
  opacity: 0;
  pointer-events: none;
}

.modal-cont__backdrop.is-hidden-modal-cont .modal-cont__form {
  transform: scale(.3);
}

.modal-cont__form {
  scroll-behavior: smooth;
  background-color: #100f0f;
  border-radius: 16px;
  margin: 0 auto;
  padding: 60px 15px;
  transition: transform .35s cubic-bezier(.4, 0, .2, 1);
  position: relative;
  transform: scale(1);
  box-shadow: 0 4px 4px #ffffff4d;
}

@media (max-width: 1019.5px) {
  .modal-cont__form {
    max-width: 415px;
  }
}

@media (min-width: 1020px) {
  .modal-cont__form {
    width: 530px;
    padding: 60px;
  }
}

.modal-cont__btn {
  fill: #f3f3f3;
  cursor: pointer;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  transition: transform .35s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 16px;
  right: 34px;
}

.modal-cont__btn:hover, .modal-cont__btn:focus {
  transform: scale(1.1);
}

.modal-cont__btn--icon {
  width: 32px;
  height: 32px;
}

.modal-cont__title {
  text-align: center;
  margin-bottom: 52px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.21429;
}

@media (max-width: 1019.5px) {
  .modal-cont__title {
    max-width: 355px;
  }
}

@media (min-width: 1020px) {
  .modal-cont__title {
    width: 410px;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.21875;
  }
}

.form-cont__label {
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  display: flex;
}

.form-cont__input {
  height: 50px;
  color: #fff;
  background-color: #100f0f;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  outline: none;
  margin-top: 12px;
  padding-left: 15px;
  transition: border-color .35s cubic-bezier(.4, 0, .2, 1), box-shadow .35s cubic-bezier(.4, 0, .2, 1);
}

.form-cont__input:focus {
  box-shadow: 0 4px 4px #fff3;
}

.form-cont__input::placeholder {
  color: #999ea3;
}

.form-cont__input:invalid {
  border-color: #bc1a1a80;
  box-shadow: 0 4px 4px #bc1a1a33;
}

.form-cont__input, .form-cont__input:valid {
  border-color: #f3f3f3;
}

.form-cont__label--textarea {
  display: block;
}

.form-cont__textarea {
  height: 100px;
  width: 100%;
  resize: none;
  color: #fff;
  background-color: #100f0f;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  outline: none;
  margin-bottom: 40px;
  padding: 15px;
  transition: box-shadow .35s cubic-bezier(.4, 0, .2, 1);
}

.form-cont__textarea:focus {
  box-shadow: 0 4px 4px #fff3;
}

.form-cont__textarea::placeholder {
  color: #999ea3;
}

.form-cont__btn {
  color: #f3f3f3;
  height: 66px;
  width: 100%;
  cursor: pointer;
  background-color: #321111;
  border: none;
  border-radius: 16px;
  font-family: inherit;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  transition: box-shadow .35s cubic-bezier(.4, 0, .2, 1), background-color .35s cubic-bezier(.4, 0, .2, 1);
}

.form-cont__btn:hover, .form-cont__btn:focus {
  background-color: #bc1a1a80;
  box-shadow: 0 4px 4px #ffffff0d;
}

.order__backdrop {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  background-color: #fff3;
  padding: 40px 0;
  transition: opacity .35s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  overflow-y: scroll;
}

.order__backdrop.is-hidden-modal-order {
  opacity: 0;
  pointer-events: none;
}

.order__backdrop.is-hidden-modal-order .order {
  transform: scale(.3);
}

.order {
  scroll-behavior: smooth;
  background-color: #100f0f;
  border-radius: 16px;
  margin: 0 auto;
  padding: 60px 15px;
  transition: transform .35s cubic-bezier(.4, 0, .2, 1);
  position: relative;
  transform: scale(1);
  box-shadow: 0 4px 4px #ffffff4d;
}

@media screen and (max-width: 414.5px) {
  .order {
    max-width: 414px;
    padding: 60px 10px;
  }
}

@media screen and (min-width: 415px) {
  .order {
    width: 414px;
    padding: 60px 15px;
  }
}

@media screen and (min-width: 1020px) {
  .order {
    width: 504px;
    padding: 60px;
  }
}

.order__close-btn {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 28px;
  right: 28px;
}

.order__close-btn:hover, .order__close-btn:focus {
  transform: scale(1.1);
}

.order__close-icon {
  fill: #f3f3f3;
  display: block;
}

.order-form__title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.21429;
}

@media (min-width: 1020px) {
  .order-form__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.21875;
  }
}

.order-form__group-block:not(:last-child) {
  margin-bottom: 40px;
}

.order-form__group-heading {
  margin-bottom: 24px;
  font-weight: 500;
}

.order-form__label {
  display: block;
}

.order-form__label:not(:last-child) {
  margin-bottom: 20px;
}

.order-form__label--custom-btn {
  align-items: center;
  gap: 12px;
  display: flex;
}

.order-form__label--fw-light {
  font-weight: 300;
}

.order-form__input {
  width: 100%;
  height: 52px;
  color: #f3f3f3;
  background: #100f0f;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  outline: none;
  margin-top: 12px;
  padding: 0 16px;
  transition: border-color .35s cubic-bezier(.4, 0, .2, 1), box-shadow .35s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.order-form__input:focus {
  box-shadow: 0 4px 4px #fff3;
}

.order-form__input::placeholder {
  color: #999ea3;
}

.order-form__input:invalid {
  border-color: #bc1a1a80;
  box-shadow: 0 4px 4px #bc1a1a33;
}

.order-form__input, .order-form__input:valid {
  border-color: #f3f3f3;
}

.order-form__input--select {
  color: #999ea3;
  -webkit-appearance: none;
  appearance: none;
}

.order-form__select-wrapper {
  display: block;
  position: relative;
}

.order-form__select-icon {
  pointer-events: none;
  fill: #f3f3f3;
  position: absolute;
  top: 14px;
  right: 14px;
}

.order-form__city-option {
  color: #999ea3;
}

.order-form__delivery-choice {
  margin-bottom: 20px;
  display: flex;
}

.order-form__del-label {
  cursor: pointer;
  width: 50%;
  text-align: center;
  border: 1px solid #f3f3f3;
  justify-content: center;
  align-items: center;
  padding: 8px 31px;
  transition: border-color .35s cubic-bezier(.4, 0, .2, 1), background-color .35s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.order-form__del-label--bord-left {
  border-right: none;
  border-radius: 16px 0 0 16px;
}

.order-form__del-label--bord-right {
  border-left: none;
  border-radius: 0 16px 16px 0;
}

.order-form__delivery-input {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.order-form__delivery-input:checked + .order-form__del-label {
  color: #100f0f;
  background-color: #f3f3f3;
}

.order-form__radio-btn {
  width: 24px;
  height: 24px;
  background: #f3f3f3;
  border: 1px solid #000;
  border-radius: 50%;
  transition: background-color .35s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
  position: relative;
}

.order-form__payment-input {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.order-form__payment-input:checked + .order-form__radio-btn:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2c2c2c;
  border-radius: 50%;
  animation: .25s zoomIn;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-form__comment {
  color: inherit;
  margin-bottom: 20px;
  text-decoration: underline;
  display: block;
}

.order-form__custom-checkbox {
  width: 18px;
  height: 18px;
  border: 2px solid #f3f3f3;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.order-form__custom-checkbox--icon {
  fill: #0000;
  width: 16px;
  height: 16px;
  transition: fill .35s cubic-bezier(.4, 0, .2, 1);
}

.order-form__confirm-ckeckbox {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.order-form__confirm-ckeckbox:checked ~ .order-form__custom-checkbox .order-form__custom-checkbox--icon {
  fill: #fff;
}

.order-form__confirm-btn {
  min-width: 100%;
  color: inherit;
  cursor: pointer;
  background-color: #bc1a1a33;
  border: none;
  border-radius: 16px;
  padding: 21px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  transition: box-shadow .35s cubic-bezier(.4, 0, .2, 1), background-color .35s cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.order-form__confirm-btn:hover, .order-form__confirm-btn:focus {
  background-color: #bc1a1a80;
  box-shadow: 0 4px 4px #ffffff0d;
}

/*# sourceMappingURL=index.7f12dc68.css.map */
