.footer__section {
  @media (min-width: $tablet) {
    border-top: 1px solid rgba(243, 243, 243, 0.6);
  }
}

.footer {
  @include centeredFlex(space-between, null);
  @media (min-width: $tablet) {
    display: inline;
  }
}

.footer-logo {
  @include centeredFlex(null, center);
}

.footer-logo__svg {
  fill: $icon-fill;
  margin-right: 12px;

  @media (min-width: $tablet) {
    margin-right: 16px;
  }
}

.footer-logo__name {
  color: $icon-fill;

  @include font(16px, 19px, 600);

  @media (min-width: $tablet) {
    @include font(20px, 24px, 700);
    text-align: left;
  }
}

.footer-nav {
  display: flex;
  gap: 20px;
  margin-top: 49px;

  @media (min-width: $tablet) {
    flex-direction: column;
  }
}

.footer-nav__item {
  margin-bottom: 20px;
}

.footer-nav__link {
  color: $icon-fill;
  transition: color $animation;

  &:hover,
  &:focus {
    transition: color $animation;
    color: $link-active-col;
  }
}

.nav-contact-flex {
  @media (min-width: $tablet) {
    @include centeredFlex(space-between, center);
  }
}

.contact {
  margin-top: 34px;
}

.contact__item {
  margin-bottom: 16px;
}

.contact__link {
  color: $icon-fill;

  @include centeredFlex(null, center);
  margin-bottom: 16px;

  transition: color $animation;

  &:hover,
  &:focus {
    color: $link-active-col;
  }

  @media (min-width: $tablet) {
    margin-bottom: 45px;
  }
}

.contact__icon {
  transition: fill $animation;

  .contact__link:hover &,
  .contact__link:focus & {
    fill: $link-active-col;
  }
}

.contact__icon-location {
  margin-right: 11.75px;
  fill: $icon-fill;
}

.contact__icon-email {
  margin-right: 9.3px;
  fill: $icon-fill;
}

.social {
  margin-top: 20px;
  cursor: pointer;
}

.social-hidden-div {
  @media (min-width: $tablet) {
    display: none;
  }
}

.social-hidden {
  @media (max-width: $to-tablet) {
    display: none;
  }
}

.social__item {
  margin-bottom: 20px;
}

.social__icon {
  width: 16px;
  height: 16px;
  fill: $icon-fill;

  transition: fill $animation;

  .social__link:hover &,
  .social__link:focus & {
    fill: $link-active-col;
  }

  @media (min-width: $desktop) {
    width: 24px;
    height: 24px;
  }
}

.conditions {
  color: #979797;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-family: Roboto;
  font-size: 8px;
  margin-top: 4px;

  .contact__item {
    margin-bottom: 16px;
  }

  @include centeredFlex(center, null);

  @media (min-width: $tablet) {
    margin-top: 24px;
    font-size: 14px;
    line-height: 17px;
  }
}

.conditions_link {
  color: $icon-fill;
  text-decoration: underline;
}
