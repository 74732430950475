.order__backdrop {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  transition: opacity $animation;
  overflow-y: scroll;
  padding: 40px 0;
}
.order__backdrop.is-hidden-modal-order {
  opacity: 0;
  pointer-events: none;
}
.order__backdrop.is-hidden-modal-order .order {
  transform: scale(0.3);
}
.order {
  margin: 40px 0;
  position: relative;
  scroll-behavior: smooth;
  background-color: $bc-color;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.3);
  transform: scale(1);
  transition: transform $animation;
  padding: 60px 15px;
  margin: 0 auto;

  @media screen and (max-width: $to-mobile) {
    max-width: 414px;
    padding: 60px 10px;
  }

  @media screen and (min-width: $mobile) {
    width: 414px;
    padding: 60px 15px;
  }

  @media screen and (min-width: $tablet) {
    width: 504px;
    padding: 60px;
  }
}

.order__close-btn {
  position: absolute;
  top: 28px;
  right: 28px;
  padding: 0;
  border: none;
  background: transparent;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.order__close-icon {
  display: block;
  fill: $icon-fill;
}

.order-form__title {
  margin-bottom: 40px;
  @include font(28px, 34px, 700);
  text-align: center;
  @media (min-width: $tablet) {
    @include font(32px, 39px, 700);
  }
}

.order-form__group-block {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.order-form__group-heading {
  margin-bottom: 24px;
  font-weight: 500;
}

.order-form__label {
  display: block;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &--custom-btn {
    @include centeredFlex(null);
    gap: 12px;
  }

  &--fw-light {
    font-weight: 300;
  }
}

.order-form__input {
  outline: none;
  display: block;
  width: 100%;
  height: 52px;
  margin-top: 12px;
  padding: 0 16px;
  color: $primary-text-col;
  background: $input-bg-col;
  border: 1px solid $input-bd-col;
  border-radius: 16px;
  transition: border-color $animation, box-shadow $animation;

  &:focus {
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.2);
  }
  &::placeholder {
    color: $placeholder-col;
  }
  &:invalid {
    border-color: $link-active-col;
    box-shadow: 0px 4px 4px rgba(188, 26, 26, 0.2);
  }
  &,
  &:valid {
    border-color: $primary-text-col;
  }

  &--select {
    color: $placeholder-col;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.order-form__select-wrapper {
  position: relative;
  display: block;
}

.order-form__select-icon {
  position: absolute;
  pointer-events: none;
  top: 14px;
  right: 14px;

  fill: $icon-fill;
}

.order-form__city-option {
  color: $placeholder-col;
}

.order-form__delivery-choice {
  display: flex;
  margin-bottom: 20px;
}

.order-form__del-label {
  cursor: pointer;
  @include centeredFlex();
  width: 50%;
  padding: 8px 31px;
  text-align: center;
  border: 1px solid $decoration-bg-col;
  transition: border-color $animation, background-color $animation;

  &--bord-left {
    border-right: none;
    border-radius: 16px 0 0 16px;
  }

  &--bord-right {
    border-left: none;
    border-radius: 0 16px 16px 0;
  }
}

.order-form__delivery-input {
  @include visualHidden();
  &:checked + .order-form__del-label {
    background-color: $decoration-bg-col;
    color: #100f0f;
  }
}

.order-form__radio-btn {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: $primary-text-col;
  border: 1px solid #000000;
  border-radius: 50%;
  transition: background-color $animation;
}

.order-form__payment-input {
  @include visualHidden();

  &:checked + .order-form__radio-btn {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: block;
      width: 12px;
      height: 12px;
      background: #2c2c2c;
      border-radius: 50%;
      animation: zoomIn;
      animation-duration: 250ms;
    }
  }
}

.order-form__comment {
  display: block;
  margin-bottom: 20px;
  color: inherit;
  text-decoration: underline;
}

.order-form__custom-checkbox {
  position: relative;
  @include centeredFlex();
  width: 18px;
  height: 18px;
  border: 2px solid $decoration-bg-col;
  border-radius: 3px;
}
.order-form__custom-checkbox--icon {
  fill: transparent;
  width: 16px;
  height: 16px;
  transition: fill $animation;
}

.order-form__confirm-ckeckbox {
  @include visualHidden();

  &:checked ~ .order-form__custom-checkbox .order-form__custom-checkbox--icon {
    fill: $secondary-text-col;
  }
}

.order-form__confirm-btn {
  display: block;
  min-width: 100%;
  padding: 21px 0;
  @include font(20px, 24px, 700);
  color: inherit;
  border: none;
  border-radius: 16px;
  background-color: $btn-bg-col;
  cursor: pointer;
  transition: box-shadow $animation, background-color $animation;
  &:hover,
  &:focus {
    background-color: $link-active-col;
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.05);
  }
}
