// font-family: 'Inter', sans-serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Roboto', sans-serif;

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../fonts/Montserrat-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}
