.products {
  position: relative;
}
.products-container {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.products__title {
  @include visualHidden;
}

.products__item {
  display: flex;
  align-items: center;
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @media screen and (min-width: $desktop) {
    margin: 0 90px;
  }
}

.products__content {
  @media screen and (max-width: $to-tablet) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media screen and (min-width: $tablet) and (max-width: $to-desktop) {
    width: 406px;
    margin-left: 64px;
    margin-right: auto;
  }
  @media screen and (min-width: $desktop) {
    width: 406px;
    margin-left: 90px;
    margin-right: auto;
  }
  &--right {
    @media screen and (min-width: $tablet) and (max-width: $to-desktop) {
      width: 443px;
      margin-left: auto;
      margin-right: 64px;
    }
    @media screen and (min-width: $desktop) {
      width: 443px;
      margin-left: auto;
      margin-right: 90px;
    }
  }
}

.products__caption {
  @media screen and (max-width: $to-tablet) {
    @include font(20px, 24px, 700);
  }
  @media screen and (min-width: $tablet) {
    @include font(32px, 39px, 600);
  }
}

.products__text {
  @media screen and (max-width: $to-tablet) {
    display: none;
  }
  @media screen and (min-width: $tablet) {
    @include font(16px, 20px, 400);
  }
}

.productSlides {
  display: none;
  animation: slideInUp;
  animation-duration: 0.5s;
}

.products__image {
  border-radius: 50%;
  box-shadow: 0px 0px 50px #7c7070;
}

.products__next,
.products__prev {
  cursor: pointer;
  position: absolute;
  top: -10px;
  left: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  transform: translate(-50%, 50%);
}
.products__prev {
  top: 100%;
  border-radius: 3px 0 0 3px;
  transform: translate(-50%, -50%);
}

.products__icon {
  @media screen and (max-width: $to-tablet) {
    width: 16px;
    height: 16px;
  }
  @media screen and (min-width: $tablet) {
    width: 32px;
    height: 32px;
  }
  fill: $icon-fill;
  &--prev {
    transform: rotate(180deg);
  }
}

.products__btn {
  cursor: pointer;
  color: $btn-text-col;
  border: none;
  border-radius: 8px;
  background-color: $btn-bg-col;
  @media screen and (max-width: $to-tablet) {
    padding: 4.5px 18.5px;
    margin-top: 16px;
  }
  @media screen and (min-width: $tablet) {
    padding: 9.5px 57px;
    margin-top: 20px;
  }
  transition: background-color $animation;
  &:hover,
  &:focus {
    background-color: $btn-bg-active-col;
  }
}
