html {
  scroll-behavior: smooth;
}

body {
  background-color: $bc-color;
  color: $primary-text-col;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;

  &.modal-open-contact,
  &.modal-open-order,
  &.modal-menu-open {
    overflow: hidden;
  }
}

ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.container {
  margin: 0 auto;
  padding: 0 15px;

  @media (max-width: $to-mobile) {
    max-width: 415px;
  }

  @media (min-width: $mobile) {
    width: 415px;
  }

  @media (min-width: $tablet) {
    width: 960px;
  }

  @media (min-width: $desktop) {
    width: 1140px;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-top: 0;
  margin-bottom: 0;
}
