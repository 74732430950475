// colors
$decoration-bg-col: #f3f3f3;
$bc-color: #100f0f;
$primary-text-col: #f3f3f3;
$secondary-text-col: #ffffff;
$accent-text-col-mob: #bc1a1a;
$accent-text-col: rgba(188, 26, 26, 0.71);

// icons
$icon-fill: #f3f3f3;
$icon-fill-active: rgba(188, 26, 26, 0.5);
// hover,focus
$link-active-col: rgba(188, 26, 26, 0.5);

// button
$btn-text-col: #f3f3f3;
$btn-bg-col: rgba(188, 26, 26, 0.2);
$btn-bg-active-col: rgba(188, 26, 26, 0.5);

// forms (хз, чи треба)
$input-bd-col: #f3f3f3;
$input-bg-col: #100f0f;
$placeholder-col: #999ea3;

//breakpoints
$mobile: 415px;
$tablet: 1020px;
$desktop: 1280px;

$to-mobile: 414.5px;
$to-tablet: 1019.5px;
$to-desktop: 1279.5px;

// animation
$animation: 350ms cubic-bezier(0.4, 0, 0.2, 1);
